<template xmlns="http://www.w3.org/1999/html">
  <div style="max-height: 438px; height: 438px;display: flex;align-items: center;justify-content: center;top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);">
    <div style="height: 438px;display: flex;flex-direction: column;justify-content: center;padding: 0 30px">
      <h1 style="font-size: 30px">欢迎来到</h1>
      <br>
      <h1 style="color: #3f9dfd;font-size: 40px">钦州工匠学院小程序管理后台</h1>
    </div>
    <div>
      <img src="../../assets/image/welcome.png" alt="">
    </div>

  </div>
</template>

<script>

import Bus from "@/assets/js/bus";
import CommonDialog from "@/components/CommonDialog.vue";

export default {
  name: "index",
  components: {CommonDialog},
  //components: {VueDPlayer},
  data() {
    return {
      dp: null,
      /*playOptions: {
        height: "200px",
        width: "100%",
        playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放
        muted: false, // 默认情况下静音播放
        loop: false, // 是否视频一结束就重新开始
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据，auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值，值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小，换句话说，它将按比例缩放以适应其容器
        sources: [
          {
            type: "video/mp4", // 类型
            src: 'http://localhost:8088/artisan/video/play', // url地址，在使用本地的资源时，需要用require()引入，否则控制台会报错
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息
        controlBar: {
          currentTimeDisplay: true,
          progressControl: true,  // 是否显示进度条
          playbackRateMenuButton: true, // 是否显示调整播放倍速按钮
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },*/
    }
  },

  mounted() {

  },

  methods: {
    upload() {
      // 打开文件选择框
      Bus.$emit('openUploader', {})
    },

    test() {
      console.log(1)
      Bus.$emit('showPlayerDialog', {src: 'http://localhost:8088/artisan/video/play'})
    },

    test1() {
      console.log(9)
      Bus.$emit('showPlayerDialog', {src: 'http://localhost:8088/artisan/video/play1'})
    }

    /*cancel() {
      console.log(111)

      this.$refs.videoPlayer.player.pause()
    },

    onPlayerPlay(player) {
      console.log('播放了');
    },

    onPlayerPause(player) {
      console.log('暂停中');
    }*/
  }

}
</script>

<style scoped>

</style>
